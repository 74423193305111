import * as React from "react";

import * as Button from "components/Button";
import { ButtonStyle } from "components/Button/constants";
import AxelosLogoSVG from "modules/theme/logos/axelos.svg";

import * as styles from "./styles.module.scss";

type NotFoundProps = {};

const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <main className={styles.notFound}>
      <div></div>
      <div>
        <h1>404</h1>
        <span>Oh My...</span>
        <p>
          This page has had the Axe.
          <br />
          Don't worry, you’re not lost.
        </p>
        <Button.PageLink label="Take me back to Axelos" style={ButtonStyle.BRAND_AQUA} slug="/" />
      </div>
      <div>
        <AxelosLogoSVG />
      </div>
    </main>
  );
};

export default NotFound;
